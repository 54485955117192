import { PageFlip } from "page-flip";

const book = document.querySelector("#book");
const prev = document.querySelector("#prev");
const next = document.querySelector("#next");
const count = document.querySelector("#count");

const pageCount = 40;
const pages = [];
for (let i = 1; i <= pageCount; i++) {
  const el = new Image();
  el.src = `./pages/PG_23-Libretto-${i}.png`;
  pages.push(el);
  el.classList.add("page");
  book.appendChild(el);
}
count.innerText = `1 / ${pageCount}`;

const settings = {
  width: 300,
  height: 422,
  size: "stretch",
  // set threshold values:
  minWidth: 315,
  maxWidth: 1000,
  minHeight: 420,
  maxHeight: 1350,
  showCover: true,
  mobileScrollSupport: false,
};
const pageFlip = new PageFlip(book, settings);
pageFlip.loadFromHTML(pages);

const ani = new URL(window.location.href).searchParams.get("ani");
if (ani) {
  console.log("start animation: ", ani * 1000);
  const pageCount = pageFlip.getPageCount();
  setInterval(() => {
    if (pageFlip.getCurrentPageIndex() == pageCount - 1) {
      console.log("start");
      pageFlip.flip(0);
    } else {
      pageFlip.flipNext();
    }
  }, ani * 1000);
} else {
  document.addEventListener("keydown", function (event) {
    const key = event.key;
    if (key == "ArrowLeft") {
      pageFlip.flipPrev();
    }
    if (key == "ArrowRight") {
      pageFlip.flipNext();
    }
  });
  pageFlip.on("flip", (e) => {
    count.innerText = `${e.data + 1} / ${pageCount}`;
  });
  prev.addEventListener("click", function () {
    pageFlip.flipPrev();
  });
  next.addEventListener("click", function () {
    pageFlip.flipNext();
  });
}
